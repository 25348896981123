import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FadeIn from "../components/fadeIn"
import ShapeParticles from "../components/ShapeParticles"
import { HireMeButtons } from "../components/HireMeButtons"
import TechnologyImageGrid from "./TechnologyImageGrid"

const SecondPage = props => {
  let images = getImages(props)

  return (
    <>
      <ShapeParticles />
      <Layout fixedFooter>
        <SEO title="About" />
        <FadeIn>
          <div style={styles.aboutMeContainer}>
            <div style={styles.contentContainer}>
              <TechnologyImageGrid images={images} />
              <div
                className="rightAboutContainer"
                style={styles.rightContainer}
              >
                <Titles />
                <Content />
                <HireMeButtons />
              </div>
            </div>
          </div>
        </FadeIn>
      </Layout>
    </>
  )
}

const getImages = props => {
  let images = []
  Object.keys(props.data).forEach(key => {
    images.push(props.data[key].childImageSharp.sizes)
  })
  return images
}

const Titles = () => (
  <div>
    {" "}
    <h3 style={styles.aboutMeText}>ABOUT ME</h3>
    <h1 style={styles.whoAmI}>WHO AM I?</h1>
  </div>
)

const Content = () => (
  <div>
    <p>
      Hey! I am a software engineer from Texas, currently seeking 
      a mid-level role in full-stack or mobile development. I love creating websites,
      cross-platform mobile apps, smartwatch apps, 3D models and animations, and
      VR experiences. I love to learn about and experiment with new technologies
      and make the extraordinary ordinary. In my free time, I like to fly my
      drone, listen to music, read sci-fi, and play board games.
    </p>

    <p>
      Interested in discussing how your idea can become a reality? Want to talk
      to me about development, drone photography, or your favorite science
      fiction author? Reach out to me at{" "}
      <a href="mailto::liam@mcmains.net">liam@mcmains.net</a>. I would love to
      chat.
    </p>
  </div>
)

export const query = graphql`
  query portfolioQuery {
    react: file(relativePath: { eq: "react.png" }) {
      ...portfolioFragment
    }
    git: file(relativePath: { eq: "git.png" }) {
      ...portfolioFragment
    }
    js: file(relativePath: { eq: "js.png" }) {
      ...portfolioFragment
    }
    swift: file(relativePath: { eq: "swift.png" }) {
      ...portfolioFragment
    }
    unity: file(relativePath: { eq: "unity.png" }) {
      ...portfolioFragment
    }
    xd: file(relativePath: { eq: "xd.png" }) {
      ...portfolioFragment
    }
    gatsby: file(relativePath: { eq: "gatsby.png" }) {
      ...portfolioFragment
    }
    nodeJS: file(relativePath: { eq: "node.png" }) {
      ...portfolioFragment
    }
    firebase: file(relativePath: { eq: "firebase.png" }) {
      ...portfolioFragment
    }
  }
`

export const styles = {
  aboutMeContainer: {},
  contentContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
  },
  rightContainer: {
    width: "45%",
  },
  aboutMeText: {
    fontSize: "2rem",
    marginBottom: 0,
  },
  whoAmI: {
    fontSize: "3rem",
    marginTop: 10,
    marginLeft: 20,
    color: "#666",
  },
  readMyArticles: {
    fontSize: "3rem",
    marginTop: 40,
    marginLeft: 20,
    color: "#666",
    marginBottom: 10,
  },
  readArticlesText: {
    width: "100%",
    textAlign: "center",
  },
}

export default SecondPage
