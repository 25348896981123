import React from "react"
import Button from "./button"
import VerticallyCenteredRow from "./verticallyCenteredRow"

export class HireMeButtons extends React.Component {
  render() {
    return (
      <VerticallyCenteredRow
        style={{
          padding: "50px 0px 50px 0px",
        }}
      >
        <Button title="HIRE ME" primary external to="/contact" />
        <Button title="SEE MY RESUME" to="resume.pdf" />
      </VerticallyCenteredRow>
    )
  }
}
