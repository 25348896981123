import React from "react"
import { Link } from "gatsby"

const Button = props => {
  let renderLinkContent = () => (
    <h3
      className="notBackground"
      style={props.primary ? style.primaryText : style.secondaryText}
    >
      {props.title}
    </h3>
  )

  let { external } = props

  if (external) {
    return (
      <Link
        to={props.to}
        style={props.primary ? style.primary : style.secondary}
      >
        {renderLinkContent()}
      </Link>
    )
  } else {
    return (
      <a
        href={props.to}
        target="_blank"
        rel="noopener noreferrer"
        style={props.primary ? style.primary : style.secondary}
      >
        {renderLinkContent()}
      </a>
    )
  }
}

const style = {
  primary: {
    backgroundColor: "#222",
    borderRadius: 30,
    maxWidth: 150,
    maxHeight: 60,
    textAlign: "center",
    marginRight: 15,
  },
  secondary: {
    backgroundColor: "white",
    borderRadius: 30,
    maxWidth: 300,
    maxHeight: 60,
    textAlign: "center",
    borderWidth: 1,
    borderColor: "#222",
    borderStyle: "solid",
  },
  primaryText: {
    color: "white",
    padding: 20,
    marginTop: 0,
    fontSize: "110%",
  },
  secondaryText: {
    padding: 20,
    marginTop: 0,
    fontSize: "110%",
  },
}

export default Button
